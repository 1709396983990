import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Yup } from '../../../../utils/yup-locale';
import { Nationality } from '../../../../domain/contact-information';
import { AssistedTicketPassenger } from '../../../../domain/tickets';
import { FormTextFieldController } from './components/text-field-controller';
import { FormSelectController } from './components/select-controller';
// import { ICompanion } from '../../../../domain/companions';
// import BadgeNameList from '../../../companions/components/badge-name-list';
// import SavePassengerCheckbox from '../../../companions/components/save-passenger-checkbox';

interface Props {
  passenger: AssistedTicketPassenger;
  validationSchema: Yup.ObjectSchema<Partial<AssistedTicketPassenger>>;
  onChange: (key: keyof AssistedTicketPassenger, value: number | string) => void;
  //companions: ICompanion[];
}

export const AssistedTicketPassengerForm = ({ passenger, validationSchema, onChange }: Props) => {
  const { t } = useTranslation();

  const form = useForm({ mode: 'all', defaultValues: passenger, resolver: yupResolver(validationSchema) });

  // const onSelectCompanions = (companion: ICompanion) => {
  //   form.reset(companion);
  //   onChange("firstName", companion.firstName);
  //   onChange("lastName", companion.lastName);
  //   onChange("gender", companion.gender);
  //   onChange("nationality", companion.nationality);
  // };

  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={3}>
        <Typography variant="body1" color="secondary" fontWeight={700}>
          {t('checkout_contact_information.passenger_information.title')}
        </Typography>
        {/* <BadgeNameList companions={companions} setActiveCompanion={onSelectCompanions} /> */}
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="firstName"
              label={t('checkout_contact_information.passenger_information.inputs.name')}
              placeholder={t('checkout_contact_information.passenger_information.inputs.name_placeholder')}
              onChange={(val) => {
                onChange('firstName', val);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="lastName"
              label={t('checkout_contact_information.passenger_information.inputs.last_name')}
              placeholder={t('checkout_contact_information.passenger_information.inputs.last_name_placeholder')}
              onChange={(val) => {
                onChange('lastName', val);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormSelectController
              name="gender"
              label={t('checkout_contact_information.passenger_information.inputs.gender')}
              placeholder={t('checkout_contact_information.passenger_information.inputs.gender_placeholder')}
              withTitle
              options={[
                { value: 'Male', label: t('checkout_contact_information.passenger_information.inputs.male') },
                { value: 'Female', label: t('checkout_contact_information.passenger_information.inputs.female') },
                { value: 'Unspecified', label: t('checkout_contact_information.main_traveler.inputs.other') },
              ]}
              onChange={(event) => {
                onChange('gender', event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={4}>
            <FormSelectController
              name="nationality"
              label={t('checkout_contact_information.main_traveler.inputs.nationality')}
              placeholder={t('checkout_contact_information.main_traveler.inputs.nationality_placeholder')}
              withTitle
              options={[
                { value: Nationality.Mexican, label: t('checkout_contact_information.main_traveler.inputs.mexican') },
                { value: Nationality.American, label: t('checkout_contact_information.main_traveler.inputs.american') },
                { value: Nationality.Other, label: t('checkout_contact_information.main_traveler.inputs.other') },
              ]}
              onChange={(event) => {
                onChange('nationality', event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="telephone"
              type="number"
              label={t('checkout_contact_information.passenger_information.inputs.phone')}
              placeholder={t('checkout_contact_information.passenger_information.inputs.phone_placeholder')}
              inputProps={{ min: 0 }}
              onChange={(val) => {
                onChange('telephone', val);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="age"
              type="number"
              label={t('checkout_contact_information.passenger_information.inputs.age')}
              placeholder={t('checkout_contact_information.passenger_information.inputs.age_placeholder')}
              inputProps={{ min: 0 }}
              onChange={(val) => {
                onChange('age', +val);
              }}
            />
          </Grid>
        </Grid>
        {/* <SavePassengerCheckbox
          control={form.control}
          companions={companions}
          onChange={(val) => {
            onChange('savePassenger', val);
          }}
        /> */}
      </Stack>
    </FormProvider>
  );
};
