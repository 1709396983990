import { v4 as uuidV4 } from 'uuid';
import { useState, useEffect } from 'react';
import { ICompanion, COMPANIONS_KEY } from '../../../domain/companions';
import Firebase from '../../../legacy/components/Firebase';

const updateCompanions = async (firebase: Firebase, uid: string, data: ICompanion[] ) => {
  firebase.update(uid, { [COMPANIONS_KEY]: data });
};

export const useCompanions = (user, firebase) => {
  /* === STATES ======================================================================= */

  const [companions, setCompanions] = useState<ICompanion[]>([]);

  const getCompanions = (): ICompanion[] => {
    if (!user) return []; // empty array

    return user[COMPANIONS_KEY] || [];
  };

  useEffect(() => {
    setCompanions(getCompanions());
  }, [user]);

  const saveCompanions = (companions) => {
    //window.localStorage.setItem('CBX_USER_DATA', JSON.stringify(companions));
    updateCompanions(firebase, user.uid, companions);
  };

  /* === HANDLERS ===================================================================== */
  const generateCompanionId = () => uuidV4();

  /**
   * @param {ICompanion} companion
   *
   * @returns {ICompanion}
   */
  const setCompanionId = (companion) => ({ ...companion, id: companion.id || generateCompanionId() });

  /**
   * @param {ICompanion | ICompanion[]} newCompanions
   */
  const addCompanions = (newCompanions: ICompanion | ICompanion[]) => {
    if (Array.isArray(newCompanions)) {
      addMultipleCompanions(newCompanions.map(setCompanionId));
      return;
    }

    addOneCompanion(setCompanionId(newCompanions));
  };

  const addOneCompanion = (companion: ICompanion) => {
    setCompanions((prev) => {
      if (prev.some((existing) => existing.id === companion.id)) {
        console.error(`Companion with id ${companion.id} already exists.`);
        return prev; // Do not add duplicate companions
      }
      const newCompanions = [...prev, companion];
      saveCompanions(newCompanions);
      return newCompanions;
    });
  };

  const addMultipleCompanions = (companions: ICompanion[]) => {
    setCompanions((prev) => {
      const uniqueCompanions = companions.filter(
        (companion) => !prev.some((existing) => existing.id === companion.id),
      );
      const newCompanions = [...prev, ...uniqueCompanions];
      saveCompanions(newCompanions);
      return newCompanions;
    });
  };

  /**
   * @param {ICompanion} companion
   */
  const updateCompanion = (id: string, updatedFields: Partial<ICompanion>) => {
    setCompanions((prev) => {
      const newCompanions = prev.map((companion) =>
        companion.id === id ? { ...companion, ...updatedFields } : companion,
      );
      saveCompanions(newCompanions);
      return newCompanions;
    });
  };

  /**
   * @param {ICompanion} companion
   */
  const deleteCompanion = (id: string) => {
    setCompanions((prev) => {
      const newCompanions = prev.filter((companion) => companion.id !== id);
      saveCompanions(newCompanions);
      return newCompanions;
    });
  };

  /**
   * @param {ICompanion} companion
   */
  const getIndexOfExistingCompanion = (companion) => {
    return companions.findIndex((savedCompanion) => savedCompanion.id === companion.id);
  };

  /* === RETURN ======================================================================= */
  return {
    companions,
    addCompanions,
    deleteCompanion,
    updateCompanion,
  };
};
