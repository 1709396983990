import React from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { shuttlePassengersSchema } from '../../../../domain/checkout';
import { ShuttlePassenger } from '../../../../domain/shuttle';
import { FormTextFieldController } from './components/text-field-controller';
import Firebase from '../../../../legacy/components/Firebase';
import BadgeNameList from '../../../companions/components/badge-name-list';
import { useCompanions } from '../../../travelers/hooks/use-companion';
import { ICompanion } from '../../../../domain/companions';
import SavePassengerCheckbox from '../../../companions/components/save-passenger-checkbox';

// ********************************************************************************
type Props = {
  index: number;
  passengersQ: number;
  cartShuttleItem: ShuttlePassenger;
  handleCartChange: (key: string, val: string | boolean) => void;
  user: any;
  firebase: Firebase;
};
export const ShuttleContactForm: React.FC<Props> = ({
  index,
  passengersQ,
  cartShuttleItem,
  handleCartChange,
  user,
  firebase,
}) => {
  const { t } = useTranslation();
  const { companions } = useCompanions(user, firebase);

  // == State ===============================================================
  const form = useForm({
    mode: 'all',
    defaultValues: {
      firstName: cartShuttleItem.firstName,
      lastName: cartShuttleItem.lastName,
      email: cartShuttleItem.email,
    },
    resolver: yupResolver(shuttlePassengersSchema),
  });

  const onItemUpdate = (key: string, val: string | boolean) => {
    handleCartChange(key, val);
  };

  // const onSelectCompanions = (companion: ICompanion) => {
  //   form.reset(companion);
  //   onItemUpdate('firstName', companion.firstName);
  //   onItemUpdate('lastName', companion.lastName);
  //   onItemUpdate('email', companion.email);
  // };

  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={1}>
        <Typography variant="body2" color="secondary">
          {t('shuttle_shopping_cart_summary.passenger')} {index} {t('of')} {passengersQ}
        </Typography>
        {/* <BadgeNameList companions={companions} setActiveCompanion={onSelectCompanions} /> */}
        <Stack direction="column" gap={3}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="firstName"
                label={t('checkout_contact_information.main_traveler.inputs.name')}
                placeholder={t('checkout_contact_information.main_traveler.inputs.name_placeholder')}
                inputProps={{ minLength: 2, maxLength: 50 }}
                onChange={(val) => {
                  onItemUpdate('firstName', val);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="lastName"
                label={t('checkout_contact_information.main_traveler.inputs.last_name')}
                placeholder={t('checkout_contact_information.main_traveler.inputs.last_name_placeholder')}
                inputProps={{ minLength: 2, maxLength: 100 }}
                onChange={(val) => {
                  onItemUpdate('lastName', val);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="email"
                label={t('checkout_contact_information.main_traveler.inputs.email')}
                placeholder={t('checkout_contact_information.main_traveler.inputs.email_placeholder')}
                type="email"
                onChange={(val) => {
                  onItemUpdate('email', val);
                }}
              />
            </Grid>
          </Grid>
        </Stack>
        {/* <SavePassengerCheckbox
          control={form.control}
          companions={companions}
          onChange={(val) => {
            onItemUpdate('savePassenger', val);
          }}
        /> */}
      </Stack>
    </FormProvider>
  );
};
